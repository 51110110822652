<script setup lang="ts">
import { onMounted, ref, computed } from "vue";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import { useRoute, useRouter } from "vue-router";
import { TwoButton } from "@wegift/two-components";
import { InformationSlide, CustomerLogo } from "../../components";
import { useLinkDetailsStore } from "../../stores/linkDetails";
import {
  getCurrentCredit,
  getRemainingDuration,
  getSenderName,
} from "../../utils/stores/utils";

import "vue3-carousel/dist/carousel.css";
import { RouteNames } from "../../utils/routes";
import { event as gaEvent } from "vue-gtag";

const LAST_SLIDE_INDEX = 2;
const currentSlide = ref<number>(0);

const route = useRoute();
const router = useRouter();

function next() {
  if (currentSlide.value < LAST_SLIDE_INDEX) {
    currentSlide.value++;
    return;
  }
  router.push({ name: RouteNames.Home, query: route.query });
}
const linkDetailsStore = useLinkDetailsStore();
const timeRemaining = getRemainingDuration(
  linkDetailsStore.expiryDate as string
);
const currentCredit = getCurrentCredit(linkDetailsStore);
const senderName = getSenderName(linkDetailsStore);
const slideOneTitle = `${senderName} has sent you a balance of ${currentCredit}`;
const slideThreeTitle = `No need to use it all right away. You have ${timeRemaining} before it expires`;

// Create a computed property for the aria-label
const balanceAriaLabel = computed(
  () => `Your balance amount is ${currentCredit}`
);

onMounted(() => {
  gaEvent("page_view", {
    page_title: "Onboarding",
    hostname: window.location.hostname,
  });
});
</script>

<template>
  <div
    class="customisations-background h-screen md:flex md:flex-col md:justify-center"
    :style="{ '--bg-color': linkDetailsStore.customisation.backgroundColour }"
  >
    <div
      class="m-auto flex h-full flex-col bg-white px-4 py-6 md:h-fit md:max-w-lg md:rounded-lg"
    >
      <CustomerLogo
        v-if="!!linkDetailsStore.customisation.logoUrl"
        class="customisations-customer-logo"
      ></CustomerLogo>
      <Carousel
        class="flex-1"
        v-model="currentSlide"
        aria-label="Onboarding information"
      >
        <Slide :key="0" tabindex="0">
          <InformationSlide
            :imgURL="
              linkDetailsStore.selfServeCustomisations?.media ||
              '/static/img/line-background.png'
            "
            :title="slideOneTitle"
            :extraMessageHeading="
              linkDetailsStore.selfServeCustomisations?.header
            "
            :extraMessage="linkDetailsStore.selfServeCustomisations?.message"
            useImgAsBackground
          >
            <template
              v-if="!linkDetailsStore.selfServeCustomisations?.media"
              v-slot:banner
            >
              <div
                class="font-f37lineca absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform rounded-lg bg-black px-5 py-4 text-2.5xl text-white"
                :aria-label="balanceAriaLabel"
                role="text"
                tabindex="0"
              >
                {{ currentCredit }}
              </div>
            </template>
          </InformationSlide>
        </Slide>
        <Slide :key="1" tabindex="0">
          <InformationSlide
            imgURL="/static/img/gift-cards.svg"
            title="Spend this at a range of merchants"
            useImgAsBackground
            imgAlt="Gift cards illustration"
          ></InformationSlide>
        </Slide>
        <Slide :key="2" tabindex="0">
          <InformationSlide
            imgURL="/static/img/circle-with-clock.svg"
            :title="slideThreeTitle"
            message="We will remind you before your balance expires."
            imgAlt="Clock illustration showing time remaining"
          ></InformationSlide>
        </Slide>
        <template #addons>
          <div
            class="fixed bottom-0 left-0 my-2 w-full flex-col pb-4 md:relative md:pb-0"
          >
            <Pagination class="pb-2" aria-label="Slide pagination" />
            <TwoButton
              @click="next"
              :class="{
                'btn-shadow btn-primary w-60':
                  !linkDetailsStore.customisation.primaryColour,
                'btn-shadow customisations-button w-60 text-white':
                  !!linkDetailsStore.customisation.primaryColour,
              }"
              :style="{
                '--bg-color': linkDetailsStore.customisation.primaryColour,
              }"
            >
              {{ currentSlide < LAST_SLIDE_INDEX ? "Continue" : "Get Started" }}
            </TwoButton>
          </div>
        </template>
      </Carousel>
    </div>
  </div>
</template>

<style>
.carousel {
  display: flex;
  flex-direction: column;
}
.carousel__viewport {
  flex: 1;
}
.carousel__track {
  height: 100%;
}
.carousel__slide {
  align-items: initial;
}
.carousel__slide--visible {
  transform: rotateY(0);
}
.carousel__pagination-button::after {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #f2e7f8;
}
.carousel__pagination-button:hover::after,
.carousel__pagination-button--active::after {
  background-color: #000000;
}
.btn-shadow {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.13), 0px 9px 19px rgba(0, 0, 0, 0.3);
}
.customisations-background {
  background-color: var(--bg-color);
}
.customisations-button {
  background-color: var(--bg-color) !important;
  border: 1px solid var(--bg-color) !important;
  color: rgb(255 255 255) !important;
}
.customisations-customer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
